<template>
  <a-table
    :columns="columns"
    :data-source="list"
    :loading="loading"
    :pagination="{ defaultPageSize: 10 }"
    :scroll="{ x: 1800, y: 600 }"
    :row-key="(record) => record.id"
  >
    <template slot="id" slot-scope="item">
      <b>{{ item.id }}</b>
    </template>

    <template slot="poster" slot-scope="item">
      <!--<spinner v-if="loading" />-->
      <div v-if="!item.poster">no photo</div>
      <div v-else>
        <img
          style="width: 80px; height: 80px; object-fit: cover"
          alt="profile_img"
          :src="item.poster"
        />
      </div>
    </template>

    <template slot="gallery_title" slot-scope="item">
      <a-row align="middle" class="fl-no-wrap" type="flex">
        <!--        <a-avatar v-if="item.files && item.files.length && filesUrl + item.files[0].file"-->
        <!--          :size="86"-->
        <!--          :src="filesUrl + item.files[0].file"-->
        <!--          class="mr-1x"-->
        <!--          icon="user"-->
        <!--          shape="square"-->
        <!--        />-->
        <!--        <a-icon
          :style="{ fontSize: '36px' }"
          class="mr-2x"
          type="video-camera"
        />-->
        {{ item.title || "Нет заголовки" }}
      </a-row>
    </template>

    <template slot="slug" slot-scope="item">
      {{ item.slug }}
    </template>

    <template slot="created_at" slot-scope="item">
      {{ moment(item.created_at).format("LLL") }}
    </template>

    <template slot="pub_date" slot-scope="item">
      {{ moment(item.pub_date).format("LLL") }}
    </template>

    <template slot="updated_at" slot-scope="item">
      {{ moment(item.updated_at).format("LLL") }}
    </template>

    <template slot="operation" slot-scope="item">
      <a-row class="btns" type="flex" align="middle" justify="end">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({
              name: 'video-detail',
              params: { id: item.id },
              query: { type: 'video' }
            })
          "
        >
          <!--{{ $t("Edit") }}-->
          <a-icon type="edit" class="action-btns" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item)"
        >
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </a-row>
    </template>
  </a-table>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
          width: "70px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          width: "50%",
          scopedSlots: { customRender: "gallery_title" }
        },
        {
          title: "Slug",
          key: "slug",
          width: "40%",
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("DateOfPublication"),
          key: "pub_date",
          width: "30%",
          scopedSlots: { customRender: "pub_date" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      loading: false,
      list: []
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    async fetchData() {
      // if (this.$route.query.tab !== 'video') {
      //   return
      // }
      this.loading = true
      try {
        const data = await this.$store.dispatch("gallery/fetchVideo", {
          params: {
            category: this.$route.params.cat_id
          }
        })

        // console.log(data)
        // if (data && data.results) {
        //   const d = data.results.map((item) => ({
        //     ...item
        //     // title: this.dataParse(item.title)
        //   }))

        //   this.$set(this, "list", d)
        // }

        this.list = data

        // console.log('ptb', data)
      } catch (err) {
        console.log(err)
        this.loading = false
      }

      this.loading = false
    },
    async removeItem(arg) {
      if (arg && arg.id) {
        // console.log(arg)
        const res = await this.$store.dispatch(
          "gallery/removeItemActionVideo",
          arg
        )
        if (res) {
          const f = this.list.findIndex((item) => item.id === arg.id)

          if (f !== -1) {
            this.list.splice(f, 1)
          }
        }
      }
    }
  }
}
</script>
